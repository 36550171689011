import Resource from "../api/resource";
import request from "../utils/request";
import store from "../store/store";

class Course extends Resource {
  constructor() {
    super("courses");
  }

  save(resource, id) {
    return request({
      url: `/courses/save${id}`,
      headers: { "Content-Type": "multipart/form-data" },
      method: "post",
      data: resource,
      onUploadProgress: function(progressEvent) {
        store.commit(
          "SET_UPLOAD_PROGRESS",
          parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
        );
      }.bind(store)
    });
  }

  evaluationDelete(id) {
    return request({
      url: `/evaluation/delete/${id}`,
      method: "delete"
    });
  }


gallery()
{
  return request({
    url:`/evaluation/gallery`,
    method:"get"
  })
}
answersGallery()
{
  return request({
    url:`/answer/gallery`,
    method:"get"
  })
}
count()
{
  return request({
    url:`/answers/count`,
    method:"get"
  })
}

likes(data) {
  return request({
    url: `/evaluation/likes`,
    method: "post",
    data: data
  });
}




  authCourses(query) {
    return request({
      url: `/auth/courses`,
      method: "post",
      params: query
    });
  }

  evaluation(resource, id) {
    return request({
      url: `/evaluation/save${id && "/" + id}`,
      headers: { "Content-Type": "multipart/form-data" },
      method: "post",
      data: resource,
      onUploadProgress: function(progressEvent) {
        store.commit(
          "SET_UPLOAD_PROGRESS",
          parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
        );
      }.bind(store)
    });
  }

  advanced(resource, id) {
    return request({
      url: `/advanced/save${id && "/" + id}`,
      headers: { "Content-Type": "multipart/form-data" },
      method: "post",
      data: resource,
      onUploadProgress: function(progressEvent) {
        store.commit(
          "SET_UPLOAD_PROGRESS",
          parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          )
        );
      }.bind(store)
    });
  }

  add(id, status) {
    return request({
      url: `/courses/add/${id}/${status}`,
      method: "post"
    });
  }

  exam(){
    return request({
      url:`/questions`,
      method:"get"
    })
  }

  answers(answer,img) {
    console.log(answer);
    return request({
      url: `/answers/add`,
      method: 'post',
      data: answer
    })
  }

  answersecond(answer) {
    console.log(answer);
    return request({
      url: `/answers/add/second`,
      method: 'post',
      data: answer
    })
  }
  answers2(answer,img) {
    console.log(answer);
    return request({
      url: `/answers/addteacher`,
      method: 'post',
      data: answer
    })
  }


  contentImg(data) {
    console.log(data);
    return request({
      url: `/contentImg`,
      method: 'post',
      data: data
    })
  }
  contact_neomi_kit(data) {
    return request({
      url: `/contact/us`,
      method: 'post',
      data: data
    })
  }

  mobileGallery()
  {
    return request({
      url:`/evaluation/mobile/gallery`,
      method:"get"
    })
  }
}

export { Course as default };
