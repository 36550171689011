<template>
    <span>Welcome to <b>Vue LBD PRO</b> - a beautiful freebie for every web developer.</span>
</template>
<script>
  export default {
    name: 'notification-sample'
  }
</script>
<style>
</style>
